<template>
  <div>
    <!--  面包屑  -->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>系统管理</el-breadcrumb-item>
      <el-breadcrumb-item>菜单管理</el-breadcrumb-item>
    </el-breadcrumb>
    <!--  卡片视图区  -->
    <el-card>
      <tree-table :data="menulist" :columns="columns" :selection-type="false" class="treetable"
                  :expand-type="false" show-index index-text="#" :border="true">

      </tree-table>
    </el-card>
  </div>
</template>
<script>
export default {
  data() {
    return{
      menulist:[],
      columns: [
        {
          label: '菜单名称',
          prop: 'm_name'
        }
      ]
    }
  },
  created() {
    this.getMenuList()
  },
  methods: {
    //获取
    async getMenuList(){
      const {data:res}=await this.$http.get('menu/getLists')
      if (res.code != 200)return this.$message.error(res.msg)
      this.menulist = res.data
      console.log(this.menulist)
    }
  }
}
</script>
